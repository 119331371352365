import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging, getToken, onMessage} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBNttEicRA1uvsCt4U-u_SUAEVTbEunFkg",
  authDomain: "mwanaclick.firebaseapp.com",
  projectId: "mwanaclick",
  storageBucket: "mwanaclick.appspot.com",
  messagingSenderId: "979288995839",
  appId: "1:979288995839:web:cd46aefe75a81bde7899ca",
  measurementId: "G-P42K69TY3G"
};

    // eslint-disable-next-line
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // eslint-disable-next-line no-unused-vars
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
let analytics = getAnalytics(app);
  onMessage(messaging, (payload) => {
    // console.log("payload", payload)
    getToken(messaging, {vapidKey: "BIH0Lcu1X9Q7YXh0aJF4EDdOcD3oqzoY-cadgbvAFdHgCS_JCuAzegwGuXz489lQ6daJm3idpFJmUzuQ-6EMfsc"}).then((currentToken)=>{
      // console.log(currentToken);
    });
  });

const authentication = getAuth();

export {app, authentication,messaging};
