import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CartContext} from "../utils/cart";
import {BiMinusCircle} from "react-icons/bi";
import {baseUrl, toBase64, translate} from "../utils";
import {closePaymentModal, useFlutterwave} from 'flutterwave-react-v3'
import {useSelector} from "react-redux";
import {selectUser} from "../store/reducers/userReducer";
import {addCardSubscriptionApi, addSubscriptionApi} from "../store/actions/campaign";
import PaymentMethod from "./PaymentMethod";
import PhoneInput from "react-phone-number-input";
import Button from "react-bootstrap/Button";
import validator from "validator";
import {message} from "antd";


export default function Cart ({showModal, toggle}) {

    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal } = useContext(CartContext);
    const [method, setMethod] = useState({});
    const [payWithMno, setpayWithMno] = useState(false);
    const [isPaying, setPaying] = useState(false);
    const userData = useSelector(selectUser);

    const [phone, setPhoneNumber] = useState();
    const [value, setPhoneValue] = useState();
    const [isPaymentSubmitted , setPaymentSubmitted] = useState(false);

    const notifyRemovedFromCart = (item) => toast.error(`${item.title} removed from cart!`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
            backgroundColor: '#000',
            color: '#fff'
        }
    });

    const generateTransactionReference = () => {
        const timestamp = Date.now();
        const randomString = Math.random().toString(36).slice(2, 12).toUpperCase();

        return `TX-${timestamp}-${randomString}-WEB`;
    };

    let gt = getCartTotal().split(" ");

    const getBrandsFromCartItem = ()=>{

        let brands = '';
        cartItems.forEach((element)=>{
            brands += element.brand+",";
        });

        let filter = '';

        removeWithfilter(brands.slice(0, -1).split(',')).forEach((element)=>{
            filter += element +',';
        });

        return filter.slice(0,-1);
    };

    const removeWithfilter = (arr)=> {
        return arr.filter(function (v, i, self) {
            return i === self.indexOf(v);
        });
    }

    const config = userData.data != null ? {
        public_key: process.env["REACT_APP_FLW_PRO_KEY"],
        tx_ref: Date.now(),
        amount: gt[1],
        currency: gt[0].includes("TSH") ? 'TZS' : gt[0].toString().toUpperCase(),
        payment_options: 'card',
        customer: {
            email: userData.data.email,
            phonenumber: userData.data.mobile,
            name: userData.data.name,
        },
        reference: generateTransactionReference(),
        customizations: {
            title: 'MwanaClick',
            description: 'Payment for items '+getBrandsFromCartItem(),
            logo: 'https://apps.mwanaclick.com/public/images/logo.png',
        },
    } : {};

    /**
     * @type {({callback, onClose}: InitializeFlutterwavePayment) => void}
     */

    const makeCardPayment = useFlutterwave(config);

    const normalizePhoneNumber = (phone)=> {
        // Remove all non-numeric characters
        console.log(phone);
        return phone.replace(/[^\d]/g, '');
    };

    let base64Data;

    const makePayment =  ()=>{
        let data = [];

        if(method != null && method.name != null){

            if(method.name.toLowerCase().includes("card")){

                setPaying(false);
                if(userData.data.email == null || userData.data.email === '') {

                    alert(translate("emailRequired", "Email address is Required to proceed!"))
                    return  setTimeout(()=>{
                        window.location.href = "/profile-update";
                    }, 1000);
                }

                setPaying(true);
                return  makeCardPayment({
                    callback: (response) => {
                       setPaying(false);
                        closePaymentModal() // This will close the modal programmatically
                        data = [];

                        if(response.status === 'successful'){

                            cartItems.forEach((element)=>{
                                data.push({
                                    "pid": element.pid,
                                    "bid": element.bid,
                                    "amount": element.amount,
                                    "frequency": element.frequency,
                                    "reference_id": config.reference,
                                    "mno": method.name,
                                    'status': response.status,
                                    'transaction_id': response.transaction_id,
                                    'tx_ref': response.flw_ref,
                                    "total": gt[1],
                                    "currency": gt[0].includes("TSH") ? 'TZS' : gt[0].toString().toUpperCase()
                                });
                                // data.add();
                            });

                            let jsonData = JSON.stringify(data),
                                base64Data = toBase64(jsonData);

                            addCardSubscriptionApi({"data":base64Data}, ((res)=>{
                                let r = JSON.parse(res);

                                if(r.error === "false"){

                                    setMethod(null);
                                    clearCart();
                                    window.location.reload();
                                }
                            }), ((err)=>{
                            }))
                        }
                    },
                    onClose: () => {
                        setPaying(false);
                    },
                });
            }else{
                setPaying(true);
                setMethod(null);
                cartItems.forEach((element)=>{
                    data.push({
                        "pid": element.pid,
                        "bid": element.bid,
                        "amount": element.amount,
                        "frequency": element.frequency,
                        "reference_id": config.reference,
                        "mno": method.name,
                        "total": gt[1],
                        "currency": gt[0].includes("TSH") ? 'TZS' : gt[0].toString().toUpperCase()
                    });
                });

                let jsonData = JSON.stringify(data);
                    base64Data = toBase64(jsonData);

                if(typeof phone != "undefined" && phone !== '' || typeof value !=='undefined' && value !==''){

                    let pn = phone;

                    if(typeof phone == 'undefined') {
                        pn = value;
                    }
                    pn = normalizePhoneNumber(pn);

                    // if(pn === normalizePhoneNumber(value))
                    //     pn = normalizePhoneNumber(value);


                    console.log(phone, value);

                    addSubscriptionApi(
                        {"data":base64Data,'mobile':pn},
                        (response) => {
                            let r = JSON.parse(JSON.stringify(response));

                            setPaying(false);
                            if(r.error === 'false') {

                                setPaymentSubmitted(true);
                                clearCart();
                                setTimeout(() => {
                                    toggle();
                                    setPaymentSubmitted(false);
                                    window.location.reload();
                                }, 35000)
                            }else{
                                alert(translate('somethingMSg', "Sorry something went wrong, Please try again!"))
                            }

                        },
                        (error) => {
                            setPaying(false);
                            if (error === "No Data Found") {
                            }
                        }
                    );
                }
            }

        }else{
            return alert(translate("selectPaymentLbl", "Select Payment method to proceed!"))
        }

    };

    const notifyCartCleared = () => toast.error(`Cart cleared!`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });

    const handleRemoveFromCart = (product) => {
        removeFromCart(product)
        setMethod(null)
        setPaying(false)
        notifyRemovedFromCart(product)
    }


    return (
        showModal && (
            <div className="col-sm-12 col-md-6 col-lg-4 justify-content-center cart-model">
                <ToastContainer />
                <h1 className="text-2xl font-bold">Cart</h1>
                <div className=" mt-3" >
                    <button
                        className="btn close-btn btn-danger bg-danger mt-4  px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                        onClick={toggle} id="btnCatagory"
                    >
                        Close
                    </button>
                </div>

                {isPaymentSubmitted && (
                    <div className="col-md-12">
                        <div className="border border-3 border-success"></div>
                        <div className="card  bg-white shadow p-5">
                            <div className="mb-4 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width="75" height="75"
                                     fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path
                                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                </svg>
                            </div>
                            <div className="text-center">
                                <h1>{translate('thankyouLbl')}</h1>
                                <p>{translate('completeTransactionTbl')}</p>
                                <button className="btn btn-outline-success"
                                        onClick={() => window.location.reload()}>{translate('closeLbl', 'Close')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex flex-col gap-4">
                    {cartItems.map((item) => (
                        <div className="col-12 justify-between items-center py-3 mb-3"
                             style={{borderBottom: "1px solid rgba(100,100,100,.18)"}} key={item.id}>
                            <div className="row">
                                <div className='col-3'>
                                    <img src={baseUrl() + "/public/docs/" + item.cover} alt={item.title}
                                         className="rounded-md" width="100%"/>
                                </div>
                                <div className="col-9 justify-center">
                                    <div className="row">
                                        <h1 className="text-sm-start font-bold"
                                            style={{fontSize: "27px"}}>{item.title}</h1>
                                        {/*<Button variant="danger" onClick={() => handleRemoveFromCart(item)} id="btnSignIn" className="me-2" type="button">*/}
                                        {/*    */}
                                        {/*    {translate("close")}*/}
                                        {/*</Button>*/}
                                        {!isPaying ? (
                                            <BiMinusCircle size={23} className="text-danger" id="btnLogo"
                                                           title="Remove from cart"
                                                           onClick={() => handleRemoveFromCart(item)} style={{
                                                position: 'absolute',
                                                left: '170',
                                                marginTop: '-20'
                                            }}/>
                                        ) : null}
                                    </div>
                                    <h4 className="text-primary">{item.currency} {item.amount}</h4>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
                {
                    cartItems != null && cartItems.length > 0 ? (
                        <div className="col-12 flex-col mb-3 justify-between items-center">

                            <div className="col-12">
                                <h4 className="py-3">{translate("paymentMethodLbl", "Select Payment Method")}</h4>
                                <div className="row">
                                    {cartItems[0].method.map((el) => (
                                        <div key={el.id} className="col-2"
                                             onClick={() => {
                                                 setMethod({id: el.id, name: el.name});
                                                 setPaying(false);
                                                 if(!el.name.toString().includes('card') && userData.data != null){
                                                     if(typeof phone != "undefined")
                                                         setPhoneValue(phone);
                                                     else
                                                         setPhoneValue(userData.data.mobile)
                                                 }
                                             }} style={{
                                            border: method != null && method.name != null && method.id === el.id ? "2px solid green" : '',
                                            float: 'left',
                                            padding: "5px",
                                            borderRadius: '10px'
                                        }}>
                                            <img src={el.image} style={{width: "100%", height: '100%'}}></img>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {method != null && method.name != null && !method.name.toString().toLowerCase().includes('card') ?

                                <div className="mb-1 text-end">

                                    <hr/>
                                    <span>Note: Tanzania Only</span>
                                    <PhoneInput className="phoneInput" placeholder="Enter your phone number"
                                                defaultCountry={process.env.REACT_APP_DEFAULT_COUNTRY} international
                                                value={value} onChange={setPhoneNumber}/>

                                </div> : null}

                            <br/>
                            <hr/>
                            <h3 className="text-lg font-bold mt-4 mb-2">{translate("itemTotalLbl",'Total')}: <strong>{getCartTotal()}</strong>
                            </h3>

                            <div className='row'>
                                <div className="col-sm-12 col-lg-5 mt-3">
                                    {isPaying ? (
                                            <button
                                                className="btn btn-success px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700 ">
                                                <span className="loader" width="30px"></span>
                                            </button>
                                        ) :
                                        (<button
                                            className="btn btn-success px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => makePayment()}>
                                            Make Payment
                                        </button>)
                                    }

                                </div>

                                {isPaying ? (
                                    <div className="col-sm-12 col-lg-4 mt-3">
                                        <button
                                            className="btn btn-secondary px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                                setPaying(false)
                                                setMethod(null);
                                                clearCart()
                                                notifyCartCleared()
                                            }}
                                        >
                                            Clear Cart
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        !isPaymentSubmitted && (<h1 className="text-lg font-bold">{translate('onItemLbl')}</h1>)
                    )
                }
            </div>
        )
    )
}

Cart.propTypes = {
    showModal: PropTypes.bool,
    toggle: PropTypes.func
}
