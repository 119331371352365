import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {CartProvider} from "./utils/cart";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
        <CartProvider>
            <App />
        </CartProvider>
    </Provider>
  </BrowserRouter>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
        // console.log("hello",registration);
    }).catch((error) => {
      // console.log("hello1",error);
    });
  });
}