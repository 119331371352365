import React, {useContext, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Breathing } from "react-shimmer";
import SwiperCore, { Navigation, Pagination,Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Skeleton from "react-loading-skeleton";
import {baseUrl, convertToSlug, isLogin, placeholderImage, toBase64, translate, truncateText} from "../utils";
import { useState } from "react";
import $ from 'jquery';

import {selectUser} from "../store/reducers/userReducer";
import {useSelector} from "react-redux";
import RateCard from "./RateCard";
import Button from "react-bootstrap/Button";
import {getSubscriptionsApi, getUserSubscriptionsApi} from "../store/actions/campaign";
import {now} from "moment";

SwiperCore.use([Navigation, Pagination,Autoplay]);

const StyleTodayNewspaper = ({ isLoading, Data }) => {

    const userData = useSelector(selectUser);
    const truncate = (input) => (input?.length > 180 ? `${input.substring(0, 180)}...` : input);

    const [isloginloading, setisloginloading] = useState(true);
    const [isSubscribing, setSubscriptionRequest] = useState(false);

    const [showRateCard, setShowRateCard] = useState(false);

    const [rateCard, setRateCard] = useState({});

    const [isSubscribed, setIsSubscribed] = useState(false);

    const [subscription, setSubscription] = useState([]);

    const [isSubscription, setIsSubscription] = useState({});


    const swiperOption = {
        loop: false,
        speed: 750,
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: false,
        autoplay:{
            delay: 10000,
            disableOnInteraction: false,
        },
        pagination: { clickable: true },
    };

    useEffect(() => {
        if (isLogin()) {
            setisloginloading(false);
            isUserSubscribed();
        } else {
            setisloginloading(true);
        } // eslint-disable-next-line
    }, []);

    function showHandle(item) {
        let i = item.pages != null ? item.pages : 0,
            uid = userData.data != null ? userData.data.id : 0,
            url = '/read/' + toBase64(item.cover + "~" + uid) + '/' + i;

        if(item.free === '1'){
            return window.location.href = url;
        }

        if (!isLogin())
            return $("#btnSignIn").click();


        if (subscription.length > 0) {
            subscription.map((d)=>{

                var exp = Date.parse(d["expired_at"]),
                    now = new Date(),
                    f = d["frequency"].toLowerCase(),
                    brands = [];

                if (f !== "daily") {
                    if (d['brand'].toString().length === 1) {
                        brands = [d["brand"]];
                    } else {
                        brands = d["brand"].split(',');
                    }

                    let contains = brands.includes(item.bid),
                        sub = contains && exp >= now;

                    if (sub)
                        return window.location.href = url;
                    else
                        return handleRateCard(item);
                } else {
                    let sub = item.bid.toString().toLowerCase() ===
                        d["brand"].toString().toLowerCase() &&
                       item.id.toString() === d["pid"].toString();

                    if (sub)
                        return window.location.href = url;
                    else
                        return handleRateCard(item);
                }
            })
        }else
            return handleRateCard(item);
    }

    function handleRateCard(item){
        setShowRateCard(true);
        setRateCard({"id": item.id, "bid": item.bid, "paper": item.brand,"title": item.title, "cover": item.cover,"pages": item.pages});
        return false;
    }

//getUserSubscriptionsApi
    function isUserSubscribed(){
        setSubscriptionRequest(true);
        let pid = localStorage.getItem('pid'),
            bid = localStorage.getItem('bid');

        if(typeof pid != 'undefined' || typeof bid != 'undefined') {
            pid = '';
            bid = '';
        }
        getUserSubscriptionsApi({"pid": pid, "bid": bid,"corporate_id": userData.data.corporate_id}, ((data) => {

                if(data.error !== 'true'){
                    // setIsSubscribed(true);
                    setSubscriptionRequest(false);
                    setSubscription(data.data);
                }
            }), ((error) => {
                // console.log(error);
                setSubscriptionRequest(false);
            }))

        return false;
    }

    return (
        <div id="first-section">

            {/* news section */}
            {(Data && Data.data?.length > 0) ? (
                <Swiper {...swiperOption} className="custom-swiper">
                    {isLoading ? (
                        // Show skeleton loading when data is being fetched
                        <div className="col-12 loading_data">
                            <Skeleton height={20} count={22} />
                        </div>
                    ) :
                        (
                        Data.data.map((product) => (
                            <SwiperSlide key={"np-"+product.id}>
                                <div id="fs-main" className="h-100 inner_custom_swiper news_style_one">
                                    <div id="body-first-section" className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-xl-7 order-1 order-xl-0 col-12 d-flex">
                                                <div id="Left-first-section" className="my-auto">
                                                    <Link id="btnCatagory" className="btn" type="button"  onClick={() => showHandle(product)}>
                                                        {truncateText(product.brand, 10)}
                                                    </Link>
                                                    <div className="my-3 top-title">{truncateText(product.title, 60)}</div>
                                                     {/*<p className="mb-3 para" dangerouslySetInnerHTML={{ __html: item && truncateText(item.title,200) }}></p>*/}
                                                    <div>

                                                        <Button id="btnReadMore" className="btn mb-0" type="button" onClick={() => showHandle(product)}>
                                                            <b>{translate("read")}</b>
                                                        </Button>

                                                        {/*{!cartItems.find(item => item.id === product.id) ? (*/}
                                                        {/*    <button*/}
                                                        {/*        className='px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700'*/}
                                                        {/*        onClick={() => {*/}
                                                        {/*            addToCart(product)*/}
                                                        {/*            notifyAddedToCart(product)*/}
                                                        {/*        }*/}
                                                        {/*        }*/}
                                                        {/*    >*/}
                                                        {/*        Add to cart*/}
                                                        {/*    </button>*/}
                                                        {/*):''}*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-xl-5 order-0 order-xl-1 col-12">
                                                <div id="right-first-section">
                                                    <img src={baseUrl()+"/public/docs/"+product.cover} className="float-end snewspaper-i h-auto" id="fs-Newscard-image" fallback={<Breathing width={800} height={600} />} alt={product.title} onError={placeholderImage}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                        ))
                    )}

                    {showRateCard && (
                    <RateCard show={showRateCard} item={rateCard}
                              onHide={() => setShowRateCard(false)}></RateCard>
                    )}
                </Swiper>
            )
                : null}
        </div>
    );
}

export default StyleTodayNewspaper;
