import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {translate} from "../utils";

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod|mobile/i.test(userAgent);
}

function isIOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

const DeviceNotification = () => {
    useEffect(() => {
        const hasShownMobileNotification = localStorage.getItem('hasShownMobileNotification');

        if (isMobileDevice()) {
            const message = (
                <div>
                    <h4>{translate('welcomeLbl','Welcome to MwanaClick!')}</h4>
                    <p>{translate('welcomeMsgLbl','We\'re thrilled to have you visit us on your mobile device.')}</p>
                    <p>{translate('welcomeMsg1Lbl','For an even better experience, you will be redirected to download our app. Enjoy our services on the go!')}</p>
                </div>
            );

            toast.info(message, {
                position: "bottom-center",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    // Redirect to the appropriate app store after the toast closes
                    if (isIOS()) {
                        window.location.href = 'https://apps.apple.com/tz/app/mwanaclick/id6483937075';
                    } else {
                        window.location.href = 'https://play.google.com/store/apps/details?id=com.mcl.mwanaclick';
                    }
                }
            });

            localStorage.setItem('hasShownMobileNotification', 'true');
        }
    }, []);

    return <ToastContainer />;
}

export default DeviceNotification;
