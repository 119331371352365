import React from 'react';
import FeatureLayoutNews from "./FeatureLayoutNews";

const News = () => {
    return (
        <>
            <FeatureLayoutNews />
        </>
    )
}

export default News;
