import React from 'react';
import FeatureLayoutNewspaper from "./FeatureLayoutNewspaper";

const NewsPaper = () => {
    return (
        <>
            <FeatureLayoutNewspaper />
        </>
    )
}

export default NewsPaper;
