import React from 'react';
import FeatureLayout from './FeatureLayout'
import DeviceNotification from "./DeviceNotification";

const HomePage = () => {

    return (
        <>
            <DeviceNotification />
            <FeatureLayout />
        </>
    )
}

export default HomePage
