import React, {useEffect} from 'react';
import { BsFillPlayFill, BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Breathing } from "react-shimmer";
import SwiperCore, { Navigation, Pagination,Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Skeleton from "react-loading-skeleton";
import {baseUrl, convertToSlug, isLogin, placeholderImage, toBase64, translate, truncateText} from "../utils";
import { useState } from "react";
import $ from 'jquery';

import {selectUser} from "../store/reducers/userReducer";
import {useSelector} from "react-redux";
import RateCard from "./RateCard";
import Button from "react-bootstrap/Button";

SwiperCore.use([Navigation, Pagination,Autoplay]);

const StyleNewspaper = ({ isLoading, Data }) => {

    const userData = useSelector(selectUser);
    const truncate = (input) => (input?.length > 180 ? `${input.substring(0, 180)}...` : input);

    const [isloginloading, setisloginloading] = useState(true);

    const [showRateCard, setShowRateCard] = useState(false);

    const [rateCard, setRateCard] = useState({});

    const swiperOption = {
        loop: false,
        speed: 750,
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: false,
        autoplay:{
            delay: 10000,
            disableOnInteraction: false,
        },
        pagination: { clickable: true },
    };

    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (isLogin()) {
            setisloginloading(false);
        } else {
            setisloginloading(true);
        } // eslint-disable-next-line
    }, []);

    /**
     * Encode a string of text as base64
     *
     * @param data The string of text.
     * @returns The base64 encoded string.
     */
    function toBase64(data) {
        if (typeof btoa === "function") {
            return btoa(data);
        } else if (typeof Buffer === "function") {
            return Buffer.from(data, "utf-8").toString("base64");
        } else {
            throw new Error("Failed to determine the platform specific encoder");
        }
    }

    function showHandle(item) {

        if (!isLogin())
            return $("#btnSignIn").click();

        return handleRateCard(item);
    }

    function handleRateCard(item){
        console.log(Data);
        setShowRateCard(true);
        setRateCard({"id": item.id, "bid": item.bid, "paper": item.brand,"title": item.title, "cover": item.cover,"pages": item.pages});
        return false;
    }

    return (
        <div id="first-section">

            {/* news section */}
            {(Data && Data.data?.length > 0) ? (
                <Swiper {...swiperOption} className="custom-swiper">
                    {isLoading ? (
                        // Show skeleton loading when data is being fetched
                        <div className="col-12 loading_data">
                            <Skeleton height={20} count={22} />
                        </div>
                    ) : (
                        Data.data.map((item) => (
                            <SwiperSlide key={"np-"+item.id}>
                                <div id="fs-main" className="h-100 inner_custom_swiper news_style_one">
                                    <div id="body-first-section" className="container">
                                        <div className="row">
                                            <div className="col-xl-7 order-1 order-xl-0 col-12 d-flex">
                                                <div id="Left-first-section" className="my-auto">
                                                    <Link id="btnCatagory" className="btn" type="button" to={`/read/${toBase64(item.bid)}`}>
                                                        {truncateText(item.brand, 10)}
                                                    </Link>
                                                    <div className="my-3 top-title">{truncateText(item.title, 60)}</div>
                                                    {/*<p className="mb-3 para" dangerouslySetInnerHTML={{ __html: item && truncateText(item.title,200) }}></p>*/}
                                                    <div>
                                                        {/*{isLogin() && isSubscribed ?*/}

                                                        <Button id="btnReadMore" className="btn mb-0" type="button" onClick={() => showHandle(item)}>
                                                            <b>{translate("Read")}</b>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 order-0 order-xl-1 col-12">
                                                <div id="right-first-section">
                                                    <img src={baseUrl()+"/public/docs/"+item.cover} className="float-end fs-Newscard-image h-auto" id="fs-Newscard-image" fallback={<Breathing width={800} height={600} />} alt={item.title} onError={placeholderImage}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                        ))
                    )}


                    {showRateCard ? (
                        <RateCard show={showRateCard} item={rateCard}
                                  onHide={() => setShowRateCard(false)}></RateCard>
                    ): null}
                </Swiper>
            )
                : null}
        </div>
    );
}

export default StyleNewspaper;
