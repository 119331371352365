import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
import {apiCallBegan} from "../actions/apiActions";
import {getCartApi} from "../../utils/api";

// state
const initialState = {
    cartCounter: 0,
};

// slice
export const cartCounterSlice = createSlice({
    name: 'cartCounter',
    initialState,
    reducers: {
        counterSuccess: (cartCounter, action) => {
            console.log(action);
            let data = action ? JSON.parse(action) : 0;
            console.log(cartCounter);
            cartCounter.cartCounter = data;
        },
        counterFailure: (cartCounter, action) => {
            cartCounter.cartCounter = 0;
        }
    }
});

export const { counterSuccess,counterFailure } = cartCounterSlice.actions;
export default cartCounterSlice.reducer;


// cart
export const loadcart = (onSuccess) => {
    let data = getCartApi();
   onSuccess(data ? JSON.parse(data) : false);
};


// selectors
export const cartCounterData = (state) => state.cartCounter;
